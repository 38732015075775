import { HttpClient } from '@angular/common/http';
import {Component} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AlertDialogService } from '../_alert-dialog/alert-dialog.service';

@Component({
    selector: 'password-dialog',
    templateUrl: './password-dialog.html',
    standalone: false
})
export class PasswordDialog {

  public sOldPassword = '';
  public sNewPassword = '';
  public sConfirm = '';
  public oLogUser = JSON.parse(sessionStorage.getItem('Gesteam_User'));
  public bLoad: boolean;
  public hide1 = true;
  public hide2 = true;
  public hide3 = true;

  constructor(
    public dialogRef: MatDialogRef<PasswordDialog>,
    private _alert: AlertDialogService,
    private _http: HttpClient) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick(): void {
    if (this.sOldPassword !== '' && this.sNewPassword !== '' && this.sConfirm !== '') {
      if (this.sNewPassword === this.sConfirm) {
        this.bLoad = true;
        this._http.post<any>(localStorage.getItem('Gesteam_Base') + '/api/Connect/ChangePassword',
          {
            Login_ID: this.oLogUser.login_ID,
            OldPassword: this.sOldPassword,
            NewPassword: this.sNewPassword
          }
        ).subscribe(result => {
          if (result.isOk) {
            this.onNoClick();
            this.oLogUser.login_Connect = true;
            sessionStorage.setItem('Gesteam_User', JSON.stringify(this.oLogUser));
            localStorage.removeItem('Gesteam_Remember');
            localStorage.removeItem('Gesteam_Password');
            this._alert.openDialog('Félicitation !', {message: 'Votre mot de passe a été changé avec succès !'}, 'success');
          } else {
            this._alert.openDialog('Erreur', {message: result.message}, 'error');
            this.bLoad = false;
          }
        }, error => {
          console.error(error);
          this.bLoad = false;
        });
      } else {
        this._alert.openDialog('Erreur', {message: 'Le nouveau mot de passe et la confirmation doivent être identiques ...'}, 'error');
      }
    } else {
      this._alert.openDialog('Erreur', {message: 'Veuillez renseigner tous les champs ...'}, 'error');
    }
  }

}
