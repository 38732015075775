import { BreakpointObserver } from "@angular/cdk/layout";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { PasswordDialog } from "../components/_password-dialog/password-dialog";
import { routeAnimation, slideInAnimation } from "../exports/animation";
import { menu } from "../exports/menu";
import { ApplicationService } from "../services/application.service";
import { HttpService } from "../services/http.service";
import { NotificationService } from "../services/notification.service";
import { ToolsService } from "../services/tools.service";
import { CompteursService } from "./statistic/compteurs/compteurs.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  animations: [routeAnimation, slideInAnimation],
  standalone: false,
})
export class PagesComponent implements OnInit, AfterViewInit {
  lMenu = menu;
  activeMenu: number = 0;
  oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));
  logo = localStorage.getItem("Gesteam_Logo");
  societe = localStorage.getItem("Gesteam_Societe");
  opened: boolean = true;
  mode: any = "side";
  bSmallScreen: boolean = false;
  bOpenMobile = false;

  constructor(
    private _r: Router,
    private _ar: ActivatedRoute,
    private _dialog: MatDialog,
    private _http: HttpService,
    private _bo: BreakpointObserver,
    public _tools: ToolsService,
    public _app: ApplicationService,
    public _notifs: NotificationService,
    public _compteurs: CompteursService
  ) {
    this._r.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // vérification si mise à jour de l'application
        this._app.checkForUpdates();
        this._notifs.get();
      }
      window.scrollTo(0, 0); // Scroll jusqu'en haut de la page
    });
  }

  ngOnInit(): void {
    if (!this.oLogUser) {
      // Si pas connecté retour à la page Login
      this._r.navigate(["/"]);
    } else {
      this.activeMenu = this.lMenu.findIndex((m) =>
        this._r.url.includes(m.path)
      );

      this.getInfosSociete();

      // Vérification si l'utilisateur doit changer de mot de passe
      if (!this.oLogUser.login_Connect) {
        this._dialog.open(PasswordDialog, {
          width: "500px",
          disableClose: true,
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this._bo.observe(["(max-width: 1200px)"]).subscribe((res) => {
      if (res.matches) {
        this.mode = "over";
        this.opened = false;
        this.bSmallScreen = true;
      } else {
        this.mode = "side";
        this.opened = true;
        this.bSmallScreen = false;
      }
    });

    // if (this.oLogUser.droits["RESPONSABLE"] == 0) {
    //   this._http.GET(
    //     "/api/Exercice",
    //     null,
    //     (result) => {
    //       if (result.isOk) {
    //         const exercice = result.obj.find(
    //           (e: Exercice) => e.exercice_Annee == new Date().getFullYear()
    //         );
    //         if (exercice != null) {
    //           this._compteurs.genForSalarie(
    //             this.oLogUser.login_ID,
    //             this.oLogUser.login_ID,
    //             new Date(exercice.exercice_Debut),
    //             new Date(exercice.exercice_Fin)
    //           );
    //         }
    //       }
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
    // }
  }

  logOutProcess(): void {
    sessionStorage.clear();
    localStorage.removeItem("Gesteam_Password");
    localStorage.removeItem("Gesteam_Remember");
    this._r.navigateByUrl(this._ar.snapshot.paramMap.get("Base"));
  }

  getInfosSociete() {
    this._http.GET(
      "/api/Societe",
      null,
      (result) => {
        if (result.isOk) {
          sessionStorage.setItem("Gesteam_Societe", JSON.stringify(result.obj));
        }
      },
      (error) => {
        this._tools.showHttpError(error);
      }
    );
  }

  showMenuLink(link: any): boolean {
    let show = true;

    if (link.bSofival && localStorage.getItem("Gesteam_Base") != "sofival") {
      show = false;
    }
    if (link.responsable && this.oLogUser.droits["RESPONSABLE"] != 1) {
      show = false;
    }
    if (
      link.validationIncident &&
      this.oLogUser.droits["VALIDATION_INCIDENT"] < 1 &&
      this.oLogUser.droits["VALIDATION_INCIDENT_SELF"] < 1
    ) {
      show = false;
    }
    if (link.salarie && this.oLogUser.droits["SALARIE_VISIBLE"] < 1)
      show = false;
    if (link.salarieSelf && this.oLogUser.droits["SALARIE_VISIBLE_SELF"] < 1)
      show = false;
    if (
      link.document &&
      this.oLogUser.droits["SALARIE_VISIBLE"] < 1 &&
      this.oLogUser.droits["SALARIE_VISIBLE_SELF"] < 1
    )
      show = false;

    if (link.paie && this.oLogUser.droits["ADMIN_PAIE"] < 1) show = false;

    return show;
  }

  getGlobalsActive(): any {
    return this.lMenu.filter((m) => {
      return m.links.filter((l) => this.showMenuLink(l)).length > 0;
    });
  }

  getSublinksActive(m: any): any {
    return m.links.filter((l) => this.showMenuLink(l));
  }
}
