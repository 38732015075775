import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  addTheme(theme: string) {
    this._document.documentElement.classList.add(theme);
  }

  removeTheme(theme: string) {
    this._document.documentElement.classList.remove(theme);
  }

  checkActiveDarkTheme() {
    if (localStorage.getItem("Gesteam_Selected_Theme") == "darkMode") {
      this.addTheme("dark");
    } else {
      try {
        const splitDebut = localStorage
          .getItem("Gesteam_DarkTheme_Debut")
          .split(":");
        const splitFin = localStorage
          .getItem("Gesteam_DarkTheme_Fin")
          .split(":");
        const debut = new Date(
          new Date().setHours(
            parseInt(splitDebut[0]),
            parseInt(splitDebut[1]),
            0,
            0
          )
        );
        let fin = new Date(
          new Date().setHours(
            parseInt(splitFin[0]),
            parseInt(splitFin[1]),
            0,
            0
          )
        );
        if (fin.getTime() < debut.getTime()) {
          fin.setDate(fin.getDate() + 1);
        }

        if (new Date() >= debut && new Date() <= fin) {
          this.addTheme("dark");
        }
      } catch (ex) {}
    }
  }
}
