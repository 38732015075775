<div class="h-dvh w-full bg-500-red flex flex-col">
  <div *ngIf="bSmallScreen" class="shadow bg-[var(--main)] z-50">
    <div class="flex items-center justify-between w-full p-2">
      <div class="relative inline-block h-12 rounded-xl overflow-hidden shadow bg-[var(--main)]">
        <img *ngIf="logo != '' && logo != null" src="/img/logos/{{ logo }}" alt="" class="h-full object-cover" />
        <img *ngIf="logo == '' || logo == null" src="/img/logo.png" alt="" class="h-full object-cover" />
      </div>
      <div class="relative size-12 rounded-full shadow overflow-hidden">
        <a [routerLink]="[lMenu[lMenu.length - 1].path]" [routerLinkActive]="['active']"
          class="size-full font-black text-2xl flex items-center justify-center profil-circle">
          {{ oLogUser.login_Nom.charAt(0)
          }}{{ oLogUser.login_Prenom.charAt(0) }}
          <img *ngIf="oLogUser.login_Photo != '' && oLogUser.login_Photo != null"
            [src]="_tools.getPhotoPath(oLogUser.login_Photo)" onerror="this.onerror=null; this.remove();" />
        </a>
      </div>
    </div>
  </div>
  <mat-drawer-container class="flex-1" [autosize]="true">
    <mat-drawer *ngIf="!bSmallScreen" [mode]="mode" [opened]="opened" class="main-drawer">
      <div class="size-full bg-[var(--dark-main)] flex">
        <div class="h-full bg-black/30 py-4 px-2 flex flex-col items-center justify-between">
          <div class="relative inline-block w-20 rounded-xl overflow-hidden mb-4 shadow bg-[var(--main)]">
            <img *ngIf="logo != '' && logo != null" src="/img/logos/{{ logo }}" alt="" class="h-full object-cover" />
            <img *ngIf="logo == '' || logo == null" src="/img/logo.png" alt="" class="h-full object-cover" />
          </div>
          <div class="flex flex-col gap-2">
            @for (m of lMenu; track $index) {
            @if (getSublinksActive(m).length > 1 || m.path == 'account') {
            <button (click)="activeMenu = $index" class="card-link w-20"
              [ngClass]="activeMenu == $index ? 'active' : ''">
              <mat-icon>{{ m.icon }}</mat-icon>
              <div class="text-xs">{{ m.title }}</div>
            </button>
            } @else if (getSublinksActive(m).length > 0) {
            <button [routerLink]="m.path + '/' + getSublinksActive(m)[0].path"
              [ngClass]="activeMenu == $index ? 'active' : ''" (click)="activeMenu = $index" class="card-link w-20">
              <mat-icon>{{ getSublinksActive(m)[0].icon }}</mat-icon>
              <div class="text-xs">{{ getSublinksActive(m)[0].title }}</div>
            </button>
            }
            }
          </div>
          <div class="relative size-12 rounded-full shadow overflow-hidden"></div>
        </div>

        @if (activeMenu != null) {
        @if ((getSublinksActive(lMenu[activeMenu]).length > 1 || activeMenu == lMenu.length - 1))
        {
        <div class="relative w-[280px]">
          <div class="p-4 mb-4">
            <h1 class="text-xl text-[var(--dark-text)] text-center mb-8 font-bold">
              {{ societe }}
            </h1>
            @if (activeMenu == lMenu.length - 1) {
            <div class="size-full flex items-center justify-center">
              <div class="font-black text-2xl profil-circle size-20 rounded-full flex items-center justify-center">
                {{ oLogUser.login_Nom.charAt(0)
                }}{{ oLogUser.login_Prenom.charAt(0) }}
                <img *ngIf="
                        oLogUser.login_Photo != '' && oLogUser.login_Photo != null
                      " [src]="_tools.getPhotoPath(oLogUser.login_Photo)"
                  onerror="this.onerror=null; this.remove();" />
              </div>
            </div>
            <h4 class="text-[var(--dark-text-light)] font-black text-center my-4">
              {{ oLogUser.login_Nom }} {{ oLogUser.login_Prenom }}
            </h4>
            } @else {
            <h2 class="text-[var(--dark-text)] font-semibold">
              {{ lMenu[activeMenu].title }}
            </h2>
            <h3 class="text-sm text-[var(--dark-text-light)]">
              {{ lMenu[activeMenu].subtitle }}
            </h3>
            }
          </div>

          <div class="p-2 flex flex-col gap-2">
            @for (l of getSublinksActive(lMenu[activeMenu]); track $index) {
            <a *ngIf="showMenuLink(l)" [routerLink]="lMenu[activeMenu].path + '/' + l.path"
              [routerLinkActive]="['active']" class="rounded-link text-[var(--dark-text)]">
              <mat-icon>{{ l.icon }}</mat-icon>
              <div class="flex-1 text-sm">
                {{ l.title }}
              </div>
              <span *ngIf="l.bNew" class="alert-sm alert-info popup-animation">New</span>
              <ng-container *ngIf="
                      l.path == 'incidents' && _notifs.getNewIncidents()
                        | async as incidents
                    ">
                <span *ngIf="incidents.length > 0"
                  class="flex items-center justify-center size-5 rounded-full text-sm bg-[var(--error)] text-[var(--error-color)] font-semibold popup-animation">{{
                  incidents.length }}</span>
              </ng-container>
              <ng-container *ngIf="l.path == 'documents' && _notifs.getNewDocuments()">
                <span *ngIf="_notifs.getNewDocuments().length > 0"
                  class="flex items-center justify-center size-5 rounded-full text-sm bg-[var(--error)] text-[var(--error-color)] font-semibold popup-animation">{{
                  _notifs.getNewDocuments().length }}</span>
              </ng-container>
              <mat-icon>chevron_right</mat-icon>
            </a>
            }
          </div>
          @if (activeMenu == lMenu.length - 1) {
          <div class="p-4 mt-4">
            <button (click)="logOutProcess()" class="btn btn-warn w-full">
              <mat-icon>logout</mat-icon> Se déconnecter
            </button>
          </div>
          }
          <button (click)="activeMenu = null"
            class="absolute bottom-4 right-4 rounded-full bg-black/30 size-10 flex items-center justify-center">
            <mat-icon class="text-[var(--dark-text)]">chevron_left</mat-icon>
          </button>
        </div>
        }
        }
      </div>
    </mat-drawer>
    <mat-drawer-content [@routeAnimation]="o.isActivated ? o.activatedRoute : ''">
      <div class="size-full bg-[var(--main-light)]">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  <div *ngIf="bOpenMobile" class="fixed top-0 left-0 h-dvh w-full z-40 bg-black/30" (click)="bOpenMobile = false"></div>
  <div *ngIf="bSmallScreen" class="mobile-menu p-2 shadow bg-[var(--dark-main)] w-full"
    [ngClass]="bOpenMobile ? 'opened fixed bottom-0 left-0 z-50' : ''">
    <ng-container *ngFor="let m of getGlobalsActive(); let idx = index">
      <div class="p-1">
        @if (getSublinksActive(m).length > 1 || m.path == 'account') {
        <a [routerLink]="[m.path]" [routerLinkActive]="['active']" class="card-link flex-1"
          (click)="bOpenMobile = false">
          <mat-icon>{{ m.icon }}</mat-icon>
          <div class="text-xs">{{ m.title }}</div>
        </a>
        } @else if (getSublinksActive(m).length > 0) {
        <a [routerLink]="m.path + '/' + getSublinksActive(m)[0].path" [routerLinkActive]="['active']"
          class="card-link flex-1" (click)="bOpenMobile = false">
          <mat-icon>{{ getSublinksActive(m)[0].icon }}</mat-icon>
          <div class="text-xs">{{ getSublinksActive(m)[0].title }}</div>
        </a>
        }
      </div>
      @if (idx == 2 && getGlobalsActive().length > 4) {
      <div class="size-full flex items-center justify-center">
        <button (click)="bOpenMobile = !bOpenMobile"
          class="size-10 rounded-full flex items-center justify-center bg-[var(--primary)] text-[var(--primary-color)]">
          <mat-icon *ngIf="bOpenMobile == false">keyboard_arrow_up</mat-icon>
          <mat-icon *ngIf="bOpenMobile == true">keyboard_arrow_down</mat-icon>
        </button>
      </div>
      }
    </ng-container>
  </div>
</div>